import React from "react"
import { BoxWrapper } from "components"

const CaseStudies = ({
  boxWrapperProps,
}: // realtedCaseStudiesProps,
ICaseStudiesInteriorContainerProps) => {
  return <BoxWrapper {...boxWrapperProps}></BoxWrapper>
}

export default CaseStudies
