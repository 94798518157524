import React from "react"
import { DefaultLayout } from "layouts"
import { NewsInterior } from "containers"
import { Article } from "components"

import bgHeader from "../../images/bg-header.png"
import articleThumbnail from "../../images/blog-interior-thumbnail.png"
import articleThumbnail01 from "../../images/article-thumbnail-01.png"
import articleThumbnail02 from "../../images/article-thumbnail-02.png"
import articleThumbnail03 from "../../images/article-thumbnail-03.png"

const articleProps: IArticle = {
  thumbnail: articleThumbnail,
  publishingDate: "September 01 2021",
  title: "Optimism is the faith that leads to excellence and achievement",
  description:
    "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
  slug: "/",
  content: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.

  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
  
  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
  
  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
  
  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
  
  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit`,
}

const boxWrapperProps = {
  heroProps: {
    background: bgHeader,
    headline: "B&F News",
  },
  boxContent: <Article {...articleProps} />,
  breadcrumbProps: {
    paths: [
      {
        path: "/",
        label: "Home",
      },
      {
        path: "/news",
        label: "B&F News",
      },
      {
        path: "/news-interior",
        label: "Optimism is the faith that leads to excellence and achievement",
      },
    ],
  },
}

const relatedArticlesProps: IRelatedArticles = {
  headline: "Related news",
  articles: [
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
  ],
  actions: [
    {
      text: "View  More",
      url: "/",
    },
  ],
}

const newsProps: INewsInteriorContainerProps = {
  boxWrapperProps,
  relatedArticlesProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <NewsInterior {...newsProps} />
    </DefaultLayout>
  )
}

export default Index
